.betInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: 3px solid transparent;
  font-family: "Poppins", sans-serif;
  color: black;
  cursor: pointer;
  display: inline-block;
  outline: none;
  z-index: 8;
}

.circle {
  z-index: 9;
  position: absolute;
  border-radius: 50%;
  background: #5eead4;
  transform: translateY(-4px);
  opacity: 0;
  width: 0px;
  height: 0px;
}

.betInput:disabled {
  cursor: default;
  &:hover {
    background: #cbd1d8;
  }
  &:hover:checked {
    background: #14b8a6;
  }
}
.betInput:hover {
  background: #9faab7;
}
.betInput:checked {
  border: 3px solid #5eead4;
  background: #14b8a6;
  & + .circle {
    width: 18px;
    height: 18px;
    opacity: 1;
    display: inline-block;
    transform: translateY(0px);
    box-shadow: 3px 3px 6px rgba(0, 114, 101, 0.75);
    transition: all 0.1s ease-in-out;
  }
}
.betInput:disabled:not(:checked) {
  display: none;
}

@media (max-width: 976px) {
  .betInput {
    height: 30px;
    width: 30px;
    &:checked {
      & + .circle {
        width: 14px;
        height: 14px;
        opacity: 1;
        display: inline-block;
        transform: translateY(0px);
        box-shadow: 3px 3px 6px rgba(0, 114, 101, 0.75);
        transition: all 0.1s ease-in-out;
      }
    }
  }
}

@media (max-width: 480px) {
  .betInput {
    height: 24px;
    width: 24px;
    &:checked {
      & + .circle {
        width: 10px;
        height: 10px;
        opacity: 1;
        display: inline-block;
        transform: translateY(0px);
        box-shadow: 3px 3px 6px rgba(0, 114, 101, 0.75);
        transition: all 0.1s ease-in-out;
      }
    }
  }
}